@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
