@import '~antd/dist/antd.css';

.notranslate {
  transform: none !important;
}

body {
  font-family: 'Poppins';
  font-weight: normal;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 15px;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 15px;
    border-radius: 15px;
    background: #c1c1c1;
  }
}

body {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-1: #222222;
  --color-black-2: #2d2d2d;
  --color-blue-0-opacity: rgba(133, 190, 250, 0.5);
  --color-blue-0: #85befa;
  --color-blue-1-opacity: rgba(73, 139, 208, 0.5);
  --color-blue-1: #498bd0;
  --color-blue-2-opacity: rgba(17, 90, 174, 0.5);
  --color-blue-2: #285ba8;
  --color-blue-3-opacity: rgba(30, 79, 135, 0.5);
  --color-blue-3: #1e4f87;
  --color-blue-4: #7da5d0;
  --color-blue-5: #4190f7;
  --color-blue-6: #78b3ff;
  --color-grey-1: #faf7f7;
  --color-grey-2: #f0f1f3;
  --color-grey-3: #c1c1c1;
  --color-grey-4: #7f7f7f;
  --color-grey-5: #fafafa;
  --color-grey-6: #afafaf;
  --color-grey-7: #d1d1d1;
  --color-yellow-1: #e2ae06;
  --color-yellow-2: #e9cf79;
  --color-yellow-3: #d6a504;
  --color-green-1: #8db146;
  --color-green-2: #9ac84e;
  --color-danger-1: #eb445a;
  --color-antd-error: #ff4d4f;
  --border-radius: 5px;

  --color-gree-1-pioneer: #3b7b2b;
  --color-green-2-pioneer: #689435;
  --color-green-3-pioneer: #88b853;
  --color-green-4-pioneer: #b8d895;
  --color-green-2-pioneer-opacity: rgba(124, 177, 64, 0.2);
  --color-orange-1: #ef6023;
  --color-orange-2-pioneer: #ef7f23;
  --color-orange-3-pioneer: #f0a263;
  --color-orange-2-pioneer-opacity: rgba(239, 128, 37, 0.2);
  --color-orange-2-pioneer-opacity-2: rgba(239, 128, 37, 0.575);

  --color-bordo-1-brevant: #660d26;
  --color-bordo-2-brevant: #750d2b;
  --color-bordo-3-brevant: #690c27;
  --color-bordo-4-brevant: #8f1b42;
  --color-bordo-5-brevant: #993859;

  --color-bordo-2-brevant-opacity: #750d2a3f;

  #root {
    height: 100%;
    width: 100%;
  }

  .ant-modal-content {
    border-radius: var(--border-radius);
  }

  p {
    color: var(--color-black-1);
    font-size: 12px;
  }

  a {
    color: var(--color-black-1);
    font-weight: bold;

    &:hover {
      color: var(--color-black-1);
      text-decoration: underline;
    }
  }

  .page {
    height: 100%;
    width: 100%;
  }

  [data-rsbs-scroll] {
    overflow: hidden;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-blue-2);
    border-color: var(--color-blue-2) !important;
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--color-blue-2);
  }

  .ant-checkbox:focus .ant-checkbox-inner {
    border-color: var(--color-blue-2) !important;
  }

  .fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }

  .marker-icon,
  .marker-icon:focus {
    background-color: gray;
    border: 3px solid white;
    border-radius: 50%;
    margin: -6px 0 0 -5px !important;
    width: 10px !important;
    height: 10px !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .leaflet-div-icon {
    background-color: transparent !important;
    width: 10px !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
    height: 10px !important;
    border-radius: 10px !important;
    border: 3px solid rgb(255, 255, 255);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mi-lote-fade {
    animation: fadeIn ease 0.6s;
    -webkit-animation: fadeIn ease 0.6s;
  }

  .ant-drawer-header-close-only {
    display: flex;
    justify-content: end;
    background-color: transparent;
  }

  .ant-message {
    z-index: 1000000;
  }

  .ant-notification.ant-notification-topRight {
    z-index: 1000000;
  }

  //filtros tablas
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: gray !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper-focused {
    border-color: gray !important;
    box-shadow: none !important;
  }

  .ant-table-filter-dropdown-btns {
    .ant-btn-primary {
      background-color: gray;
      border-color: gray;
      &:hover {
        background-color: rgb(158, 157, 157);
        border-color: rgb(158, 157, 157);
      }
    }
    .ant-btn-link {
      color: rgb(95, 95, 95);
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
      color: rgba(0, 0, 0, 0.25);
    }
    .ant-dropdown-menu-item-selected {
      color: gray !important;
    }
  }

  .ant-table-filter-dropdown {
    .ant-dropdown-menu-item-selected {
      font-weight: 600;
      color: rgb(95, 95, 95);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgb(95, 95, 95);
      border-color: rgb(95, 95, 95);
    }
    .ant-checkbox-checked::after {
      border: rgb(95, 95, 95);
    }
  }
  .ant-table-filter-column {
    .ant-table-filter-trigger.active {
      color: rgb(49, 49, 49);
    }
  }
  //hasta acá fitros de tabla

  .ant-modal-confirm-btns {
    .ant-btn.ant-btn-primary {
      background-color: var(--color-blue-2);
      border: none;
      color: var(--color-white);
      font-weight: bold;

      &:hover {
        background-color: var(--color-blue-1);
        color: var(--color-white);
      }

      &:focus {
        background-color: var(--color-blue-2);
        color: var(--color-white);
      }
    }

    .ant-btn {
      font-weight: bold;
      background-color: transparent;
      border: 2px solid var(--color-blue-2);
      color: var(--color-blue-2);
      border-radius: var(--border-radius);

      &:hover {
        background-color: var(--color-blue-2);
        color: var(--color-white);
      }
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #e2ae06;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #e2ae06;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #e2ae06;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #e2ae06;
  }

  .ant-picker-today-btn {
    color: #000000d9;
  }

  .ant-picker-today-btn:hover,
  .ant-picker-month-btn:hover,
  .ant-picker-year-btn:hover,
  .ant-picker-decade-btn:hover {
    color: #000000d9;
    font-weight: bold;
  }

  .corteva {
    &_field-details-menu {
      &:hover {
        h3 {
          font-weight: bold;
          color: var(--color-blue-5);
        }
      }

      &.selected {
        h3 {
          font-weight: bold;
        }
      }
    }
  }

  .pioneer {
    .btn_pdf_download {
      .ant-btn {
        &:hover {
          color: var(--color-green-2-pioneer);
          border-color: var(--color-green-2-pioneer);
        }
        &:active {
          color: var(--color-green-2-pioneer);
          border-color: var(--color-green-2-pioneer);
        }
        &:focus {
          color: var(--color-green-2-pioneer);
          border-color: var(--color-green-2-pioneer);
        }
      }
    }

    .ant-modal-confirm-btns {
      .ant-btn.ant-btn-primary {
        background-color: var(--color-orange-1);
        border: none;
        color: var(--color-white);
        font-weight: bold;

        &:hover {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }
      }

      .ant-btn {
        font-weight: bold;
        background-color: transparent;
        border: 2px solid var(--color-green-2-pioneer);
        color: var(--color-green-2-pioneer);

        &:hover {
          color: white;
          border-color: var(--color-green-2-pioneer);
          background-color: var(--color-green-2-pioneer);
        }
      }
    }

    &_modal {
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: var(--color-green-2-pioneer) !important;
        box-shadow: 0 0 0 2px #68943570;
      }
      .ant-modal-content {
        border-radius: var(--border-radius);
      }

      .ant-modal-header {
        border-radius: var(--border-radius);
      }

      .ant-modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 25px;
      }
      .ant-btn.ant-btn-primary {
        background-color: var(--color-orange-1);
        border: none;
        color: var(--color-white);
        font-weight: bold;

        &:hover {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }
      }

      .ant-btn {
        font-weight: bold;
        background-color: transparent;
        border: 2px solid var(--color-green-2-pioneer);
        color: var(--color-green-2-pioneer);

        &:hover {
          color: white;
          border-color: var(--color-green-2-pioneer);
          background-color: var(--color-green-2-pioneer);
        }
      }
    }

    .ant-upload:hover {
      border-color: #689435 !important;
    }

    &_btnGoogleMaps {
      .ant-btn:hover,
      .ant-btn:focus {
        width: 100%;
        color: #689435;
        border-color: #689435;
      }
    }

    &_buttonMore {
      .ant-btn:focus,
      .ant-btn:hover {
        border-color: var(--color-green-2-pioneer);
        color: var(--color-green-2-pioneer);
      }
    }

    &_btn_field-or-farm-search-box {
      button {
        background-color: var(--color-orange-1) !important;
        border-color: var(--color-orange-1) !important;
      }

      button:hover {
        background-color: var(--color-orange-2-pioneer) !important;
        border-color: var(--color-orange-2-pioneer) !important;
        border-radius: var(--border-radius) !important;
      }

      input:hover {
        border-color: var(--color-orange-2-pioneer) !important;
      }

      input:focus {
        border-color: var(--color-orange-2-pioneer);
        box-shadow: 0 0 0 2px var(--color-orange-2-pioneer-opacity) !important;
      }

      input:active {
        border-color: var(--color-orange-2-pioneer) !important;
        box-shadow: 0 0 0 2px var(--color-orange-2-pioneer-opacity) !important;
      }

      .ant-input-search .ant-input:focus {
        border-color: var(--color-orange-2-pioneer) !important;
        box-shadow: 0 0 0 2px var(--color-orange-2-pioneer-opacity) !important;
      }
    }

    &_field-details-menu {
      &:hover {
        h3 {
          font-weight: bold;
          color: var(--color-green-2-pioneer);
        }
      }

      &.selected {
        h3 {
          font-weight: bold;
        }
      }
    }

    &_reactTour {
      span {
        background: var(--color-green-2-pioneer) !important;
      }

      nav {
        .fKLrJo {
          background: var(--color-green-2-pioneer);
        }
      }
    }

    .ant-switch-checked {
      background-color: var(--color-green-2-pioneer);
    }

    .ant-switch-checked:focus {
      box-shadow: var(--color-green-2-pioneer);
    }

    .ant-pagination-item-active {
      border-color: var(--color-green-2-pioneer) !important;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      &:hover {
        color: var(--color-green-2-pioneer) !important;
      }
    }
    .ant-pagination-item-link {
      //Color
      &:hover {
        border-color: var(--color-green-2-pioneer) !important;
      }
      svg {
        &:hover {
          color: var(--color-green-2-pioneer) !important;
        }
      }
    }
    .ant-table-thead {
      th {
        font-weight: 800;
        color: rgb(73, 73, 73);
      }
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active a {
      color: var(--color-green-2-pioneer) !important;
      border-color: var(--color-green-2-pioneer) !important;
    }

    .ant-pagination-item:hover,
    .ant-pagination-item:hover a {
      color: var(--color-green-2-pioneer) !important;
      border-color: var(--color-green-2-pioneer) !important;
    }

    .ant-pagination-item:focus,
    .ant-pagination-item:focus a {
      color: var(--color-green-2-pioneer) !important;
      border-color: var(--color-green-2-pioneer) !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--color-green-2-pioneer);
      color: var(--color-green-2-pioneer);
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: var(--color-green-2-pioneer);
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-color: var(--color-green-2-pioneer);
    }

    .ant-timeline-item-head-blue {
      border-color: var(--color-green-2-pioneer);
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-picker:hover,
    .ant-input-number:hover,
    .ant-input:hover,
    .ant-input:focus,
    .ant-input-affix-wrapper:hover,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-picker-focused,
    .ant-input-number-focused,
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-form input[type='checkbox']:focus {
      border-color: var(--color-green-2-pioneer);
      box-shadow: 0 0 0 2px var(--color-green-2-pioneer-opacity);
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--color-green-2-pioneer);
      border-color: var(--color-green-2-pioneer) !important;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: var(--color-green-2-pioneer) !important;
    }

    .ant-checkbox-inner:hover {
      border-color: var(--color-green-2-pioneer) !important;
    }
  }

  .pioneerBrand {
    .milote-chat-opener .open {
      background: url('iconGreen.png') no-repeat;
      background-size: contain;
    }

    .milote-chat-opener .close {
      background: url('tildeGreen.png') no-repeat;
      background-size: contain;
    }

    .ac-actionSet button {
      color: var(--color-grey-4) !important;
      border: 1px solid var(--color-orange-2-pioneer-opacity-2) !important;
      font-size: 14px;
      border-radius: 15.84px;
      cursor: pointer;
    }

    //
    .css-1bojp06 {
      img {
        content: url('arrowGreen.png');
      }
    }

    .ac-actionSet button:hover {
      border-color: var(--color-orange-2-pioneer) !important;
    }

    .ac-actionSet button:active {
      background-color: var(--color-orange-2-pioneer) !important;
      border-color: var(--color-orange-2-pioneer) !important;
      color: white !important;
    }

    .ac-actionSet button:focus {
      background-color: var(--color-orange-2-pioneer) !important;
      border-color: var(--color-orange-2-pioneer) !important;
      color: white !important;
    }

    #Bkgd,
    #Rectangle-Copy,
    #Rectangle,
    #Rectangle-Copy-3,
    #Rectangle-Copy-4,
    #Rectangle-Copy-5,
    #Rectangle-Copy-6,
    #Rectangle-Copy-2 {
      fill: var(--color-green-2-pioneer) !important;
    }
  }

  #arrowGreen {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .pioneer__btn {
    background-color: var(--color-orange-1);
    border: none;
    padding: 0 35px;
    height: 38px;
    border-radius: var(--border-radius);
    color: var(--color-white);
    font-weight: bold;

    .ant-upload {
      color: white !important;
      font-size: 16px;
    }

    &.ant-btn-ghost {
      background-color: transparent;
      border: 2px solid var(--color-green-2-pioneer) !important;
      color: var(--color-green-2-pioneer) !important;

      &:hover {
        background-color: var(--color-green-2-pioneer) !important;
        color: var(--color-white) !important;
      }

      &:focus {
        background-color: var(--color-green-2-pioneer) !important;
        color: var(--color-white) !important;
        border-color: var(--color-green-2-pioneer) !important;
      }
    }

    &:disabled,
    [disabled] {
      background-color: var(--color-grey-7);

      &:hover {
        background-color: var(--color-grey-7);
      }
    }

    &:hover {
      background-color: var(--color-orange-2-pioneer);
      color: var(--color-white);
    }

    &:focus {
      background-color: var(--color-orange-2-pioneer);
      color: var(--color-white);
    }
  }

  .brevant__btn {
    background-color: var(--color-orange-1);
    border: none;
    padding: 0 35px;
    height: 38px;
    border-radius: var(--border-radius);
    color: var(--color-white);
    font-weight: bold;

    .ant-upload {
      color: white !important;
      font-size: 16px;
    }

    &.ant-btn-ghost {
      background-color: transparent;
      border: 2px solid var(--color-bordo-2-brevant);
      color: var(--color-bordo-2-brevant);

      &:hover {
        background-color: var(--color-bordo-2-brevant);
        color: var(--color-white);
      }

      &:focus {
        background-color: var(--color-bordo-2-brevant);
        color: var(--color-white);
        border-color: var(--color-bordo-2-brevant);
      }
    }

    &:disabled,
    [disabled] {
      background-color: var(--color-grey-7);

      &:hover {
        background-color: var(--color-grey-7);
      }
    }

    &:hover {
      background-color: var(--color-orange-2-pioneer);
      color: var(--color-white);
    }

    &:focus {
      background-color: var(--color-orange-2-pioneer);
      color: var(--color-white);
    }
  }

  .ant-popover-buttons {
    .ant-btn-primary {
      background-color: #9b9b9b;
      border-color: #9b9b9b;

      &:hover {
        background-color: #b1b1b1;
        border-color: #b1b1b1;
        color: white !important;
      }
    }

    .ant-btn {
      &:hover {
        color: #9b9b9b;
        border-color: #b1b1b1;
      }
    }
  }

  .brevant {
    .btn_pdf_download {
      .ant-btn {
        &:hover {
          color: var(--color-bordo-2-brevant);
          border-color: var(--color-bordo-2-brevant);
        }
        &:active {
          color: var(--color-bordo-2-brevant);
          border-color: var(--color-bordo-2-brevant);
        }
        &:focus {
          color: var(--color-bordo-2-brevant);
          border-color: var(--color-bordo-2-brevant);
        }
      }
    }

    .ant-modal-confirm-btns {
      .ant-btn.ant-btn-primary {
        background-color: var(--color-orange-1);
        border: none;
        color: var(--color-white);
        font-weight: bold;

        &:hover {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }
      }

      .ant-btn {
        font-weight: bold;
        background-color: transparent;
        border: 2px solid var(--color-bordo-2-brevant);
        color: var(--color-bordo-2-brevant);

        &:hover {
          color: white;
          background-color: var(--color-bordo-2-brevant);
          border-color: var(--color-bordo-2-brevant);
        }
      }
    }

    &_modal {
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: var(--color-bordo-2-brevant);
        box-shadow: 0 0 0 2px #750d2a50;
      }
      .ant-modal-content {
        border-radius: var(--border-radius);
      }

      .ant-modal-header {
        border-radius: var(--border-radius);
      }

      .ant-modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 25px;
      }
      .ant-btn.ant-btn-primary {
        background-color: var(--color-orange-1);
        border: none;
        color: var(--color-white);
        font-weight: bold;

        &:hover {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-orange-2-pioneer);
          color: var(--color-white);
        }
      }

      .ant-btn {
        font-weight: bold;
        background-color: transparent;
        border: 2px solid var(--color-bordo-2-brevant);
        color: var(--color-bordo-2-brevant);

        &:hover {
          color: white;
          background-color: var(--color-bordo-2-brevant);
          border-color: var(--color-bordo-2-brevant);
        }
      }
    }

    .ant-upload:hover {
      border-color: #690c27 !important;
    }

    &_btnGoogleMaps {
      .ant-btn:hover,
      .ant-btn:focus {
        width: 100%;
        color: #690c27;
        border-color: #690c27;
      }
    }

    &_buttonMore {
      .ant-btn:focus,
      .ant-btn:hover {
        border-color: var(--color-bordo-2-brevant);
        color: var(--color-bordo-2-brevant);
      }
    }

    &_btn_field-or-farm-search-box {
      button {
        background-color: var(--color-orange-1) !important;
        border-color: var(--color-orange-1) !important;
      }

      button:hover {
        background-color: var(--color-orange-2-pioneer) !important;
        border-color: var(--color-orange-2-pioneer) !important;
        border-radius: var(--border-radius) !important;
      }

      input:hover {
        border-color: var(--color-orange-2-pioneer) !important;
      }

      input:focus {
        border-color: var(--color-orange-2-pioneer) !important;
        box-shadow: 0 0 0 2px var(--color-orange-2-pioneer-opacity) !important;
      }

      input:active {
        border-color: var(--color-orange-2-pioneer) !important;
        box-shadow: 0 0 0 2px var(--color-orange-2-pioneer-opacity) !important;
      }

      .ant-input-search .ant-input:focus {
        border-color: var(--color-orange-2-pioneer) !important;
        box-shadow: 0 0 0 2px var(--color-orange-2-pioneer-opacity) !important;
      }
    }

    &_field-details-menu {
      &:hover {
        h3 {
          font-weight: bold;
          color: var(--color-bordo-4-brevant);
        }
      }

      &.selected {
        h3 {
          font-weight: bold;
        }
      }
    }

    &_reactTour {
      span {
        background: var(--color-bordo-4-brevant) !important;
      }

      nav {
        .fKLrJo {
          background: var(--color-bordo-4-brevant);
        }
      }
    }

    .ant-switch-checked {
      background-color: var(--color-bordo-4-brevant);
    }

    .ant-switch-checked:focus {
      box-shadow: var(--color-bordo-4-brevant);
    }

    .ant-pagination-item-active {
      border-color: var(--color-bordo-4-brevant) !important;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      &:hover {
        color: var(--color-bordo-4-brevant) !important;
      }
    }
    .ant-pagination-item-link {
      //Color
      &:hover {
        border-color: var(--color-bordo-4-brevant) !important;
      }
      svg {
        &:hover {
          color: var(--color-bordo-4-brevant) !important;
        }
      }
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active a {
      color: var(--color-bordo-4-brevant) !important;
      border-color: var(--color-bordo-4-brevant) !important;
    }

    .ant-pagination-item:hover,
    .ant-pagination-item:hover a {
      color: var(--color-bordo-4-brevant) !important;
      border-color: var(--color-bordo-4-brevant) !important;
    }

    .ant-pagination-item:focus,
    .ant-pagination-item:focus a {
      color: var(--color-bordo-4-brevant) !important;
      border-color: var(--color-bordo-4-brevant) !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--color-bordo-3-brevant);
      color: var(--color-bordo-3-brevant);
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: var(--color-bordo-3-brevant);
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-color: var(--color-bordo-3-brevant);
    }

    .ant-timeline-item-head-blue {
      border-color: var(--color-bordo-3-brevant);
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-picker:hover,
    .ant-input-number:hover,
    .ant-input:hover,
    .ant-input:focus,
    .ant-input-affix-wrapper:hover,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-picker-focused,
    .ant-input-number-focused,
    .ant-input-focused,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-form input[type='checkbox']:focus {
      border-color: var(--color-bordo-2-brevant);
      box-shadow: 0 0 0 2px var(--color-bordo-2-brevant-opacity);
    }

    svg:hover,
    .ant-input-number-handler-up-inner:hover,
    .ant-input-number-handler-down-inner:hover,
    .ant-input-number-handler:hover,
    .antIcon:hover {
      border-color: var(--color-bordo-2-brevant);
      color: var(--color-bordo-2-brevant);
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--color-bordo-2-brevant);
      border-color: var(--color-bordo-2-brevant) !important;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: var(--color-bordo-2-brevant) !important;
    }

    .ant-checkbox-inner:hover {
      border-color: var(--color-bordo-2-brevant) !important;
    }
  }

  .brevantBrand {
    .milote-chat-opener .open {
      background: url('iconBordo.png') no-repeat;
      background-size: contain;
    }

    .milote-chat-opener .close {
      background: url('tildeBordo.png') no-repeat;
      background-size: contain;
    }

    .ac-actionSet button {
      color: var(--color-grey-4) !important;
      border: 1px solid var(--color-orange-2-pioneer-opacity-2) !important;
      font-size: 14px;
      border-radius: 15.84px;
      cursor: pointer;
    }

    //
    .css-1bojp06 {
      img {
        content: url('arrowBordo.png');
      }
    }

    .ac-actionSet button:hover {
      border-color: var(--color-orange-2-pioneer) !important;
    }

    .ac-actionSet button:active {
      background-color: var(--color-orange-2-pioneer) !important;
      border-color: var(--color-orange-2-pioneer) !important;
      color: white !important;
    }

    .ac-actionSet button:focus {
      background-color: var(--color-orange-2-pioneer) !important;
      border-color: var(--color-orange-2-pioneer) !important;
      color: white !important;
    }

    #Bkgd,
    #Rectangle-Copy,
    #Rectangle,
    #Rectangle-Copy-3,
    #Rectangle-Copy-4,
    #Rectangle-Copy-5,
    #Rectangle-Copy-6,
    #Rectangle-Copy-2 {
      fill: var(--color-bordo-2-brevant) !important;
    }
  }
}
